import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import OrderNotYetInvoiced from '../interfaces/output/orderNotYetInvoiced';
import OrdersNotYetInvoicedFilters from '../interfaces/filters/orderNotYetInvoicedFilters';

export type ReportsState = {
    isLoadingOrdersNotYetInvoiced: boolean;
    ordersNotYetInvoiced: Pagination<OrderNotYetInvoiced>;
    error: HttpErrorResponse | undefined;
};

const initialState: ReportsState = {
    ordersNotYetInvoiced: { content: [] as Array<OrderNotYetInvoiced> } as Pagination<OrderNotYetInvoiced>,
    isLoadingOrdersNotYetInvoiced: false,
    error: undefined
};

export const reportsSlice = createSlice({
    name: 'reports',

    initialState,
    reducers: {
        fetchOrdersNotYetInvoiced: (state: ReportsState, _action: PayloadAction<{ paging: Paging, filters?: OrdersNotYetInvoicedFilters }>) => {
            state.isLoadingOrdersNotYetInvoiced = true;
        },
        fetchOrdersNotYetInvoicedSuccess: (state: ReportsState, action: PayloadAction<Pagination<OrderNotYetInvoiced>>) => {
            state.isLoadingOrdersNotYetInvoiced = false;
            state.ordersNotYetInvoiced = action.payload;
        },
        fetchOrdersNotYetInvoicedError: (state: ReportsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingOrdersNotYetInvoiced = false;
            state.error = action.payload;
        },
        resetOrdersNotYetInvoiced: (state: ReportsState) => {
            state.ordersNotYetInvoiced = initialState.ordersNotYetInvoiced;
        }
    }
});

export const { fetchOrdersNotYetInvoiced, fetchOrdersNotYetInvoicedError, fetchOrdersNotYetInvoicedSuccess, resetOrdersNotYetInvoiced }
    = reportsSlice.actions;

export const isLoadingOrdersNotYetInvoiced = (state: RootState): boolean => state.reportsReducer.isLoadingOrdersNotYetInvoiced;
export const getOrdersNotYetInvoiced = (state: RootState): Pagination<OrderNotYetInvoiced> => state.reportsReducer.ordersNotYetInvoiced;
export default reportsSlice.reducer;