import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import Terminal from '../interfaces/output/terminal';
import TerminalApi from '../api/terminalApi';
import {
    createTerminalError, createTerminalSuccess, fetchTerminalByIdError,
    fetchTerminalByIdSuccess, fetchTerminalsError, fetchTerminalsSuccess,
    updateTerminalError, updateTerminalSuccess
} from '../reducers/terminalReducer';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import TerminalFilters from '../interfaces/filters/terminaleFilters';

function* doFetchTerminal(action: PayloadAction<string>) {
    const terminalId = action.payload;
    try {
        const response: Terminal = yield call(TerminalApi.fetchTerminalById, terminalId);
        yield put(fetchTerminalByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchTerminalByIdError(error as HttpErrorResponse));
    }
}

function* doFetchTerminals(action: PayloadAction<{ paging: Paging, filters: TerminalFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<Terminal> = yield call(TerminalApi.fetchTerminals, paging, filters);
        yield put(fetchTerminalsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchTerminalsError(error as HttpErrorResponse));
    }
}

function* doCreateTerminal(action: PayloadAction<Terminal>) {
    const terminal = action.payload;

    try {
        const response: Terminal = yield call(TerminalApi.createTerminal, terminal);

        yield put(createTerminalSuccess(response));
    }

    catch (error: unknown) {
        yield put(createTerminalError(error as HttpErrorResponse));
    }
}

function* doUpdateTerminal(action: PayloadAction<Terminal>) {
    const terminal = action.payload;

    try {
        const response: Terminal = yield call(TerminalApi.updateTerminal, terminal);

        yield put(updateTerminalSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateTerminalError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('terminal/fetchTerminalById', doFetchTerminal),
    takeLatest('terminal/fetchTerminals', doFetchTerminals),
    takeLatest('terminal/fetchTerminalsWithFilters', doFetchTerminals),
    takeLatest('terminal/createTerminal', doCreateTerminal),
    takeLatest('terminal/updateTerminal', doUpdateTerminal)];

