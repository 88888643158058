import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Accordion, Grid, Typography, AccordionDetails, CardContent, Theme, AccordionSummary } from '@mui/material';
import { Card } from '@mui/material';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormattedMessage } from 'react-intl';
import ProductArena from '../../interfaces/output/productArena';
import TerminalList from '../terminals/terminalList';
import { fetchTerminalsWithFilters, getTerminals, isLoadingTerminal } from '../../reducers/terminalReducer';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../utils/pageUtils';
import Paging from '../../interfaces/common/paging';

interface IViewProductArenaProps {
    classes?: any;
    theme?: Theme;
    productArena: ProductArena;
}

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto'
        },
        cardContent: {
            flex: 1,
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: '10px'
            },
            '&  .MuiAccordionSummary-root.Mui-expanded': {
                minHeight: 'inherit'
            }
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4)

        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular
        },
        accordion: {
            backgroundColor: 'rgba(0, 0, 0, .03)',
            marginTop: '10px'
        },
        gridItem: {
            marginBottom: theme.spacing(1)
        },
        typography: {
            marginLeft: theme.spacing(5)
        }
    })
);

const messages = {
    details: LanguageUtils.createMessage('Product Arena details'),
    name: LanguageUtils.createMessage('Name'),
    nameNo: LanguageUtils.createMessage('Name no'),
    code: LanguageUtils.createMessage('code'),
    unitGroup: LanguageUtils.createMessage('Unit Group'),
    productArenaType: LanguageUtils.createMessage('Product Arena Type'),
    terminals: LanguageUtils.createMessage('Terminals')
};

export default function ViewProductArena(props: IViewProductArenaProps): JSX.Element {
    const classes = styles();
    const { productArena } = props;
    const [paging, setPaging] = React.useState<Paging>(PageUtils.getDefaultPaging());
    const dispatch = useDispatch();
    //const propsIsLoadingTerminal = useSelector(isLoadingTerminal);
    const isLoadingObjects = [useSelector(isLoadingTerminal)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    const propsTerminals = useSelector(getTerminals);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{
        field: 'nameEng',
        sort: 'asc' as GridSortDirection
    } as GridSortItem]);
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({});

    useEffect(() => {
        if(productArena.id) {
            dispatch(fetchTerminalsWithFilters({
                paging: paging,
                filters: {
                    productArenaId: productArena.id
                }
            }));
        }
    }, [productArena.id]);

    const _onSortModelChange = (newModel: GridSortModel) => {

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...paging,
                sort: newModel
            };

            setPaging(newPagination);
            setSortModel(newModel);

            dispatch(fetchTerminalsWithFilters({
                filters: {
                    productArenaId: productArena.id
                },
                paging: newPagination
            }));
        }
    };

    return (<Grid container alignItems="flex-start">
        <Grid item md={12}>
            <Card className={classes.root} >
                <CardContent className={classes.cardContent}>
                    <Accordion expanded>
                        <AccordionSummary expandIcon={<span />}
                            aria-controls="panel1a-content" id="panel1a-header" className={classes.accordion}
                        >
                            <Typography className={classes.heading}><FormattedMessage {...messages.details} /></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={4} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.name} />: </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography gutterBottom>{productArena.nameEng || '-'}</Typography>
                                </Grid>
                                <Grid item xs={4} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.nameNo} />: </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography gutterBottom>{productArena.nameNo || '-'}</Typography>
                                </Grid>
                                <Grid item xs={4} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.code} />: </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography gutterBottom>{productArena.code || '-'}</Typography>
                                </Grid>
                                <Grid item xs={4} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.unitGroup} />: </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography gutterBottom>{productArena.unitGroup?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={4} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.productArenaType} />: </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography gutterBottom>{productArena.productArenaType?.nameNo || '-'}</Typography>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            className={classes.accordion}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            aria-label="expand"
                        >
                            <Typography className={classes.heading}><FormattedMessage {...messages.terminals} /></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <TerminalList isLoading={isLoading} rows={propsTerminals}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        dispatch(fetchTerminalsWithFilters({
                                            filters: {
                                                productArenaId: productArena.id
                                            },
                                            paging: newPage
                                        }));
                                        setPaging(newPage);
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => _onSortModelChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                        setColumnVisibilityModel(newModel)
                                    }
                                />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    );
}