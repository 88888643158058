import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';
import ResponsabilityCenter from '../interfaces/output/responsabilityCenter';

export default class ResponsabilityCenterApi {
    static fetchResponsabilityCenterById = async(responsabilityCenterId: string): Promise<ResponsabilityCenter> => {
        const { data } = await http.get<ResponsabilityCenter>(`/d365responsabilitcenter/${responsabilityCenterId}`);

        return data;
    };

    static fetchResponsabilitiesCenter = async(paging: Paging): Promise<Pagination<ResponsabilityCenter>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/d365responsabilitcenter');

        const { data } = await http.get<Pagination<ResponsabilityCenter>>(url);

        return data;
    };
}