const HelptextConstantsEnum = {
    CUSTOMER_CUSTOMERS_MAIN: 'customer-customers-main',
    CUSTOMER_PROPERTIES_MAIN: 'customer-properties-main',
    CUSTOMER_AGREEMENTS_MAIN: 'customer-agreements-main',
    CUSTOMER_AGREEMENTS_SECONDARY: 'customer-agreements-secondary',
    CUSTOMER_ORDERS_MAIN: 'customer-orders-main',
    CUSTOMER_INVOICES_MAIN: 'customer-invoices-main',
    AGREEMENT_AGREEMENTS_MAIN: 'agreement-agreements-main',
    AGREEMENT_PROPERTIES_MAIN: 'agreement-agreements-main',
    AGREEMENT_CUSTOMERS_MAIN: 'agreement-agreements-main',
    AGREEMENT_VERSIONS_MAIN: 'agreement-versions-main',
    AGREEMENT_TRANSIT_DISCOUNTS_MAIN: 'agreement-transit-discounts-main',
    AGREEMENT_PRICE_LISTS_MAIN: 'agreement-price-lists-main',
    AGREEMENT_SPECIAL_PRICE_MAIN: 'agreement-special-price-main',
    SURCHARGE_RATE_MAIN: 'surcharge-rate-main',
    USERS: 'users-main',
    TERMINALS: 'terminals-main',
    PRODUCT_ARENAS: 'product-arenas-main',
    REPORT_ORDERS_NOT_INVOICES: 'reports-orders-not-yet-invoiced'
};

export default HelptextConstantsEnum;

