import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';
import OrderNotYetInvoiced from '../interfaces/output/orderNotYetInvoiced';
import OrdersNotYetInvoicedFilters from '../interfaces/filters/orderNotYetInvoicedFilters';

export default class ReportsApi {
    static fetchOrdersNotYetInvoiced = async(paging: Paging, filters?: OrdersNotYetInvoicedFilters): Promise<Pagination<OrderNotYetInvoiced>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/reportnotyetinvoiced');

        const { data } = await http.get<Pagination<OrderNotYetInvoiced>>(url);

        return data;
    };
}