import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Seller from '../interfaces/output/seller';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import ResponsabilityCenter from '../interfaces/output/responsabilityCenter';

export type ResponsabilityCenterState = {
    responsabilityCenter: ResponsabilityCenter;
    isLoading: boolean;
    responsabilitiesCenter: Pagination<ResponsabilityCenter>;
    error: HttpErrorResponse | undefined;
};

const initialState: ResponsabilityCenterState = {
    responsabilityCenter: {} as ResponsabilityCenter,
    responsabilitiesCenter: { content: [] as Array<ResponsabilityCenter> } as Pagination<ResponsabilityCenter>,
    isLoading: false,
    error: undefined
};

export const responsabilityCenterSlice = createSlice({
    name: 'responsabilityCenterSlice',

    initialState,
    reducers: {
        fetchResponsabilitiesCenter: (state: ResponsabilityCenterState, _action: PayloadAction<{ paging: Paging }>) => {
            state.isLoading = true;
        },
        fetchResponsabilitiesCenterSuccess: (state: ResponsabilityCenterState, action: PayloadAction<Pagination<ResponsabilityCenter>>) => {
            state.isLoading = false;
            state.responsabilitiesCenter = action.payload;
        },
        fetchResponsabilitiesCenterError: (state: ResponsabilityCenterState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchResponsabilityCenterById: (state: ResponsabilityCenterState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchResponsabilityCenterByIdSuccess: (state: ResponsabilityCenterState, action: PayloadAction<ResponsabilityCenter>) => {
            state.isLoading = false;
            state.responsabilityCenter = action.payload;
        },
        fetchResponsabilityCenterByIdError: (state: ResponsabilityCenterState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchResponsabilitiesCenter, fetchResponsabilitiesCenterSuccess, fetchResponsabilitiesCenterError,
    fetchResponsabilityCenterById, fetchResponsabilityCenterByIdError, fetchResponsabilityCenterByIdSuccess } = responsabilityCenterSlice.actions;

export const isLoadingResponsabilityCenter = (state: RootState): boolean => state.responsabilityCenterReducer.isLoading;
export const getResponsabilitiesCenter = (state: RootState): Pagination<ResponsabilityCenter> => state.responsabilityCenterReducer.responsabilitiesCenter;
export const getResponsabilityCenter = (state: RootState): ResponsabilityCenter => state.responsabilityCenterReducer.responsabilityCenter;

export default responsabilityCenterSlice.reducer;