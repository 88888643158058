import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import OrderNotYetInvoiced from '../interfaces/output/orderNotYetInvoiced';
import ReportsApi from '../api/reportsApi';
import { fetchOrdersNotYetInvoicedError, fetchOrdersNotYetInvoicedSuccess } from '../reducers/reportsReducer';
import OrdersNotYetInvoicedFilters from '../interfaces/filters/orderNotYetInvoicedFilters';

function* doFetchOrdersNotYetInvoiced(action: PayloadAction<{ paging: Paging, filters?: OrdersNotYetInvoicedFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<OrderNotYetInvoiced> = yield call(ReportsApi.fetchOrdersNotYetInvoiced, paging, filters);
        yield put(fetchOrdersNotYetInvoicedSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchOrdersNotYetInvoicedError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('reports/fetchOrdersNotYetInvoiced', doFetchOrdersNotYetInvoiced)];

