export const DisplayOptions = {
    Shown: 1,
    Hidden: 2
};

export enum ObjectType {
    Agreement,
    AgreementVersionSpecialPrice,
    AgreementVersionTransitDiscount,
    Customer,
    Invoice,
    InvoiceLine,
    Product,
    PriceList,
    FactorSets,
    Order,
    OrderUnit,
    GtsOrder,
    GtsOrderUnit,
    OrderUnitService,
    OrderUnitServiceProduct,
    OrderUnitServiceProductSurcharge,
    GtsOrderUnitService,
    GtsOrderUnitDepot,
    GtsOrderUnitDangerousGoods,
    Service,
    Simulation,
    SurchargeRate,
    User,
    Terminal,
    ProductArena

}

export enum FormType {
    Add,
    AddVersion,
    AddCustomerAgreementFromAgreement,
    AddCustomerAgreementFromCustomer,
    AddLine,
    AddUnit,
    AddUnitService,
    AddUnitServiceProduct,
    AddUnitServiceProductSurcharge,
    Edit,
    EditLine,
    EditUnit,
    EditUnitService,
    EditUnitServiceProduct,
    EditUnitServiceProductSurcharge,
    EditPriceListVersionProductViaProduct,
    EditPriceListVersionProductViaPriceList,
    EditAgreementVersionPriceListViaAgreement,
    EditAgreementVersionPriceListViaPriceList,
    EditVersion,
    ChangeStatus,
    ChangeOverriddenPrice,
    ChangeServiceProducts,
    Import,
    ImportStaging,
    Clone
}