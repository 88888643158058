import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import ResponsabilityCenterApi from '../api/responsabilityCenterApi';
import {
    fetchResponsabilitiesCenterError, fetchResponsabilitiesCenterSuccess,
    fetchResponsabilityCenterByIdError, fetchResponsabilityCenterByIdSuccess
} from '../reducers/responsabilityCenterReducer';
import ResponsabilityCenter from '../interfaces/output/responsabilityCenter';

function* doFetchResponsabilityCenter(action: PayloadAction<string>) {
    const responsabilityCenterId = action.payload;
    try {
        const response: ResponsabilityCenter = yield call(ResponsabilityCenterApi.fetchResponsabilityCenterById, responsabilityCenterId);
        yield put(fetchResponsabilityCenterByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchResponsabilityCenterByIdError(error as HttpErrorResponse));
    }
}

function* doFetchResponsabilitiesCenter(action: PayloadAction<{ paging: Paging }>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<ResponsabilityCenter> = yield call(ResponsabilityCenterApi.fetchResponsabilitiesCenter, paging);
        yield put(fetchResponsabilitiesCenterSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchResponsabilitiesCenterError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('responsabilityCenter/fetchResponsabilityCenterById', doFetchResponsabilityCenter),
    takeLatest('responsabilityCenter/fetchResponsabilitiesCenter', doFetchResponsabilitiesCenter)];

