import React, { forwardRef, useEffect } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { useDispatch, useSelector } from 'react-redux';
import WorkflowUtils from '../../../utils/workflowUtils';
import PageUtils from '../../../utils/pageUtils';
import ProductArena from '../../../interfaces/output/productArena';
import { isCreatingProductArena, isLoadingProductArenas } from '../../../reducers/productArenaReducer';
import { fetchProductArenaTypes, getProductArenaTypes, isLoadingProductArenaTypes } from '../../../reducers/productArenaTypeReducer';
import { fetchUnitGroups, getUnitGroups, isLoadingUnitGroups } from '../../../reducers/unitGroupReducer';
import ProductArenaType from '../../../interfaces/output/productArenaType';
import UnitGroup from '../../../interfaces/output/unitGroup';
import { Grid } from '@mui/material';
import ProductArenaStep1Validation from './validation/productArenaStep1Validation';
import Loader from '../../common/widgets/loader';
import GenericTextField from '../../common/widgets/genericTextField';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import { fetchTerminals, getTerminals, isLoadingTerminal } from '../../../reducers/terminalReducer';
import Terminal from '../../../interfaces/output/terminal';
// import { useIntl } from 'react-intl';

interface IProductArenaStep1Props {
    productArena: ProductArena;
    onChange: any;
    showValidationError?: boolean
}

const ATTRIBUTES = {
    NAME: 'nameEng',
    NAME_NO: 'nameNo',
    CODE: 'code',
    UNIT_GROUP: 'unitGroup',
    PRODUCT_ARENA_TYPE: 'productArenaType'
};

const messages = {
    name: LanguageUtils.createMessage('Name'),
    nameNo: LanguageUtils.createMessage('Name NO'),
    code: LanguageUtils.createMessage('Code'),
    unitGroup: LanguageUtils.createMessage('Unit group'),
    productArenaType: LanguageUtils.createMessage('Product Arena Type'),
    terminals: LanguageUtils.createMessage('Terminals')
};

const ProductArenaFormStep1 = forwardRef((props: IProductArenaStep1Props, ref: any): JSX.Element => {
    const { productArena, onChange, showValidationError } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const isLoadingObjects = [useSelector(isCreatingProductArena), useSelector(isLoadingProductArenas),
        useSelector(isLoadingProductArenaTypes), useSelector(isLoadingUnitGroups), useSelector(isLoadingTerminal)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    const productArenaTypes = useSelector(getProductArenaTypes).content;
    const unitGroups = useSelector(getUnitGroups).content;
    const terminals = useSelector(getTerminals).content;

    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchProductArenaTypes({ paging }));
        dispatch(fetchUnitGroups({ paging }));
        dispatch(fetchTerminals({ paging }));
    }, [dispatch]);

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {

            case ATTRIBUTES.NAME: {
                isValid = ProductArenaStep1Validation.validateInputString(productArena.nameEng);
                break;
            }
            case ATTRIBUTES.CODE: {
                isValid = ProductArenaStep1Validation.validateInputString(productArena.code);
                break;
            }
            case ATTRIBUTES.PRODUCT_ARENA_TYPE: {
                isValid = ProductArenaStep1Validation.validateInputNumber(productArena.productArenaType?.id);
                break;
            }
            case ATTRIBUTES.UNIT_GROUP: {
                isValid = ProductArenaStep1Validation.validateInputNumber(productArena.unitGroup?.id);
                break;
            }
        }

        return !isValid;
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        type="string"
                        name="nameEng"
                        label={messages.name}
                        value={productArena.nameEng}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.NAME)}
                        required
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        type="string"
                        name="nameNo"
                        label={messages.nameNo}
                        value={productArena.nameNo}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.NAME_NO)}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        type="string"
                        name="code"
                        label={messages.code}
                        value={productArena.code}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.CODE)}
                        required
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<ProductArenaType>
                        options={productArenaTypes}
                        value={productArena.productArenaType}
                        onChange={(obj: ProductArenaType | null) => onChange('productArenaType', obj)}
                        getOptionLabel={(option: ProductArenaType) => option.nameNo}
                        placeholder={messages.productArenaType}
                        error={setValidationState(ATTRIBUTES.PRODUCT_ARENA_TYPE)}
                        required
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<UnitGroup>
                        options={unitGroups}
                        value={productArena.unitGroup}
                        onChange={(obj: UnitGroup | null) => onChange('unitGroup', obj)}
                        placeholder={messages.unitGroup}
                        error={setValidationState(ATTRIBUTES.UNIT_GROUP)}
                        required
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<Terminal>
                        options={terminals}
                        value={productArena?.terminalIds}
                        onMultipleChange={(obj: Terminal[]) => onChange('terminalIds', obj.map(o => o.id))}
                        placeholder={messages.terminals}
                        compareFn={(o: Terminal, id: number) => o.id === id}
                        getOptionLabel={(option: Terminal) => option?.name || ''}
                        multiple
                    />
                </Grid>
            </Grid>
        </Loader >
    );
});

export default ProductArenaFormStep1;