import { http } from '../utils/http';
import Customer from '../interfaces/output/customer';
import Paging from '../interfaces/common/paging';
import CustomerFilters from '../interfaces/output/filters';
import SortFormatterUtils from '../utils/sortFormatterUtils';
import moment from 'moment';

export default class CustomerApi {
    static fetchCustomerById = async(customerId: string): Promise<Customer> => {
        const { data } = await http.get<Customer>(`customer/${customerId}`);

        return data;
    };

    static fetchCustomers = async(paging: Paging, filters: CustomerFilters): Promise<Array<Customer>> => {
        const formattedFilters = {
            ...filters,
            orderFromDate: filters.orderFromDate ? moment(filters.orderFromDate).toISOString() : undefined,
            orderToDate: filters.orderToDate ? moment(filters.orderToDate).toISOString() : undefined
        };

        const url = SortFormatterUtils.addSortingToUrl(paging, formattedFilters, '/customer/search');
        const { data } = await http.get<Array<Customer>>(url);

        return data;
    };

    static createCustomer = async(customer: Customer): Promise<Customer> => {
        const newCustomer = {
            name: customer.name,
            gtsId: customer.gtsId,
            dynamicsId: customer.dynamicsId,
            vatId: customer.vatId,
            visibleInGTS: customer.visibleInGTS,
            countryId: customer.country?.id,
            corporateId: customer.corporate?.id,
            segmentId: customer.segment?.id,
            invoiceFrequencyId: customer.invoiceFrequency?.id,
            invoiceSeparationId: customer.invoiceSeparation?.id,
            paymentDeadlineId: customer.paymentDeadline?.id,
            sellerId: customer.seller?.id,
            statusId: 1,
            groupId: customer.group?.id,
            vatCodeId: customer.vatCode?.id,
            emailAddressInvoice: customer.emailAddressInvoice,
            invoiceFormatId: customer.invoiceFormat?.content?.id,
            emailAddressCSV: customer.emailAddressCSV,
            languageId: customer.language?.id,
            attachPDF: customer.specificationAttachPDF ?? 'false',
            attachCSV: customer.specificationAttachCSV ?? 'false',
            unitGroupId: customer.unitGroup?.id,
            nextInvoiceDate: customer.nextInvoiceDate
        };

        const { data } = await http.post<any, Customer>('customer', newCustomer);

        return data;
    }

    static updateCustomer = async(customer: Customer): Promise<Customer> => {
        const newCustomer = {
            name: customer.name,
            gtsId: customer.gtsId,
            dynamicsId: customer.dynamicsId,
            vatId: customer.vatId,
            visibleInGTS: customer.visibleInGTS,
            countryId: customer.country?.id,
            statusId: customer.vatId && customer.dynamicsId && customer.status?.id === 1 ? 2 : customer.status?.id,
            corporateId: customer.corporate?.id,
            segmentId: customer.segment?.id,
            invoiceFrequencyId: customer.invoiceFrequency?.id,
            invoiceSeparationId: customer.invoiceSeparation?.id,
            paymentDeadlineId: customer.paymentDeadline?.id,
            sellerId: customer.seller?.id,
            groupId: customer.group?.id,
            vatCodeId: customer.vatCode?.id,
            emailAddressInvoice: customer.emailAddressInvoice,
            invoiceFormatId: customer.invoiceFormat?.content?.id,
            emailAddressCSV: customer.emailAddressCSV,
            languageId: customer.language?.id,
            attachPDF: customer.specificationAttachPDF ?? 'false',
            attachCSV: customer.specificationAttachCSV ?? 'false',
            unitGroupId: customer.unitGroup?.id,
            nextInvoiceDate: customer.nextInvoiceDate
        };

        const { data } = await http.put<any, Customer>(`customer/${customer.id}`, newCustomer);

        return data;
    }
    static syncCustomerGts = async(customerId: number): Promise<Customer> => {
        const { data } = await http.put<undefined, Customer>(`customer/${customerId}/gts`);

        return data;
    }

    static deactivateCustomer = async(customer: Customer): Promise<Customer> => {
        const newCustomer = {
            name: customer.name,
            gtsId: customer.gtsId,
            dynamicsId: customer.dynamicsId,
            vatId: customer.vatId,
            visibleInGTS: true,
            countryId: customer.country?.id,
            statusId: 0,
            corporateId: customer.corporate?.id,
            segmentId: customer.segment?.id,
            invoiceFrequencyId: customer.invoiceFrequency?.id,
            invoiceSeparationId: customer.invoiceSeparation?.id,
            paymentDeadlineId: customer.paymentDeadline?.id,
            sellerId: customer.seller?.id,
            groupId: customer.group?.id,
            vatCodeId: customer.vatCode?.id,
            emailAddressInvoice: customer.emailAddressInvoice,
            invoiceFormatId: customer.invoiceFormat?.content?.id,
            languageId: customer.language?.id
        };

        const { data } = await http.put<any, Customer>(`customer/${customer.id}/delete`, newCustomer);

        return data;
    }

    static processCreditInvoices = async(id: number): Promise<any> => {
        const { data } = await http.put<any, Customer>(`customer/${id}/processCreditInvoices`);

        return data;
    }
}