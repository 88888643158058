import React, { forwardRef, useEffect } from 'react';
import LanguageUtils from '../../utils/LanguageUtils';
import { Grid, FormControl, FormControlLabel, Checkbox } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../utils/pageUtils';
import Loader from '../common/widgets/loader';
import WorkflowUtils from '../../utils/workflowUtils';
import { fetchCorporates, getCorporates, isLoadingCorporates } from '../../reducers/customerCorporateReducer';
import { fetchSellers, getSellers, isLoadingSellers } from '../../reducers/sellerReducer';
import { fetchSegments, getSegments, isLoadingSegment } from '../../reducers/segmentReducer';
import { fetchCountries, getCountries, isLoadingCountry } from '../../reducers/countryReducer';
import { fetchCustomerGroups, getGroups, isLoadingGroups } from '../../reducers/customerGroupReducer';
import { isCreatingCustomer, isLoadingCustomer, isUpdatingCustomer } from '../../reducers/customerReducer';
import Customer from '../../interfaces/output/customer';
import GenericAutocomplete from '../common/widgets/genericAutocomplete';
import CustomerCorporate from '../../interfaces/output/customerCorporate';
import Seller from '../../interfaces/output/seller';
import CustomerSegment from '../../interfaces/output/customerSegment';
import Country from '../../interfaces/output/countryOut';
import CustomerGroup from '../../interfaces/output/customerGroup';
import GenericTextField from '../common/widgets/genericTextField';
import CustomerStatus from '../../interfaces/output/customerStatus';
import { fetchStatuses, getStatuses } from '../../reducers/statusReducer';
import { FormattedMessage } from 'react-intl';
import VatCode from '../../interfaces/output/vatCode';
import { fetchVatCodes, getVatCodes } from '../../reducers/vatReducer';
import CustomerStep1Validation from './validation/customerStep1Validation';
import Language from '../../interfaces/output/language';
import UnitGroup from '../../interfaces/output/unitGroup';
import { fetchUnitGroups, getUnitGroups, isLoadingUnitGroups } from '../../reducers/unitGroupReducer';
import { fetchLanguages, getLanguages } from '../../reducers/languageReducer';

interface AddCustomerStep1Props {
    customer: Customer;
    onChange: any;
    showValidationError?: boolean;
}

const messages = {
    corporate: LanguageUtils.createMessage('Customer corporate'),
    seller: LanguageUtils.createMessage('Seller'),
    segment: LanguageUtils.createMessage('Segment'),
    country: LanguageUtils.createMessage('Country'),
    group: LanguageUtils.createMessage('Group'),
    name: LanguageUtils.createMessage('Name'),
    visibleInGTS: LanguageUtils.createMessage('Visible in gts'),
    vatCode: LanguageUtils.createMessage('Vat code'),
    emailInvoice: LanguageUtils.createMessage('Email invoice'),
    status: LanguageUtils.createMessage('Status'),
    nameHelpText: LanguageUtils.createMessage('Customer name help text'),
    corporateHelpText: LanguageUtils.createMessage('Customer corporate help text'),
    sellerHelpText: LanguageUtils.createMessage('Customer seller help text'),
    segmentHelpText: LanguageUtils.createMessage('Customer market segment help text'),
    countryHelpText: LanguageUtils.createMessage('Customer country help text'),
    vatCodeHelpText: LanguageUtils.createMessage('Customer vat code help text'),
    emailHelpText: LanguageUtils.createMessage('Customer email help text'),
    emailCsvHelpText: LanguageUtils.createMessage('Customer emails csv help text'),
    groupHelpText: LanguageUtils.createMessage('Customer customer group help text'),
    emailCsv: LanguageUtils.createMessage('Emails Csv'),
    language: LanguageUtils. createMessage('Language'),
    languageHelpText: LanguageUtils.createMessage('Customer language help text'),
    sendPDF: LanguageUtils.createMessage('Send PDF'),
    sendCSV: LanguageUtils.createMessage('Send CSV'),
    unitGroup: LanguageUtils.createMessage('Customer unit group')
};

const ATTRIBUTES = {
    NAME: 'name',
    SELLER: 'seller',
    CORPORATE: 'corporate',
    CUSTOMER_SEGMENT: 'segment',
    COUNTRY: 'country',
    EMAIL: 'emailAddressInvoice',
    GROUP: 'customerGroup',
    EMAILCSV: 'emailAddressCSV',
    LANGUAGE: 'language'
};

const AddCustomerStep1 = forwardRef((props: AddCustomerStep1Props, ref: any): JSX.Element => {
    const { customer, onChange, showValidationError } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const corporates = useSelector(getCorporates).content;
    const sellers = useSelector(getSellers).content;
    const segments = useSelector(getSegments).content;
    const countries = useSelector(getCountries).content;
    const customerGroups = useSelector(getGroups).content;
    const customerStatuses = useSelector(getStatuses).content;
    const vatCodes = useSelector(getVatCodes).content;
    const unitGroups = useSelector(getUnitGroups).content;
    const languages = useSelector(getLanguages).content;
    const isLoadingObjects = [useSelector(isCreatingCustomer), useSelector(isLoadingCustomer), useSelector(isLoadingCorporates),
        useSelector(isLoadingSellers), useSelector(isLoadingSegment), useSelector(isUpdatingCustomer),useSelector(isLoadingUnitGroups), useSelector(isLoadingCountry), useSelector(isLoadingGroups)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchCorporates({ paging }));
        dispatch(fetchSellers({ paging }));
        dispatch(fetchSegments());
        dispatch(fetchCountries({ paging }));
        dispatch(fetchCustomerGroups({ paging }));
        dispatch(fetchVatCodes({ paging }));
        dispatch(fetchUnitGroups({ paging }));
        dispatch(fetchStatuses());
        dispatch(fetchLanguages({ paging }));
    }, [dispatch]);

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {
            case ATTRIBUTES.NAME: {
                isValid = CustomerStep1Validation.validateInputString(customer.name);
                break;
            }
            case ATTRIBUTES.SELLER: {
                isValid = CustomerStep1Validation.validateInputString(customer.seller?.name);
                break;
            }
            case ATTRIBUTES.CORPORATE: {
                isValid = CustomerStep1Validation.validateInputString(customer.corporate?.name);
                break;
            }
            case ATTRIBUTES.CUSTOMER_SEGMENT: {
                isValid = CustomerStep1Validation.validateInputString(customer.segment?.name);
                break;
            }
            case ATTRIBUTES.COUNTRY: {
                isValid = CustomerStep1Validation.validateInputString(customer.country?.name);
                break;
            }
            case ATTRIBUTES.EMAIL: {
                isValid = CustomerStep1Validation.validateInputString(customer.emailAddressInvoice) &&
                CustomerStep1Validation.validateEmail(customer.emailAddressInvoice);
                break;
            }
            case ATTRIBUTES.EMAILCSV: {
                isValid = CustomerStep1Validation.validateEmail(customer.emailAddressCSV, true);
                break;
            }
        }

        return !isValid;
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="standard">
                        <GenericTextField<string>
                            name="name"
                            label={messages.name}
                            required
                            error={setValidationState(ATTRIBUTES.NAME)}
                            value={customer.name}
                            onChange={onChange}
                            helperText={messages.nameHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item sm={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<CustomerCorporate>
                            options={corporates}
                            value={customer.corporate}
                            onChange={(obj: CustomerCorporate | null) => onChange('corporate', obj)}
                            error={setValidationState(ATTRIBUTES.CORPORATE)}
                            required
                            placeholder={messages.corporate}
                            helperText={messages.corporateHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<Seller>
                            options={sellers}
                            value={customer.seller}
                            onChange={(obj: Seller | null) => onChange('seller', obj)}
                            error={setValidationState(ATTRIBUTES.SELLER)}
                            required
                            placeholder={messages.seller}
                            helperText={messages.sellerHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<CustomerSegment>
                            options={segments}
                            value={customer.segment}
                            onChange={(obj: CustomerSegment | null) => onChange('segment', obj)}
                            placeholder={messages.segment}
                            error={setValidationState(ATTRIBUTES.CUSTOMER_SEGMENT)}
                            required
                            helperText={messages.segmentHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<Country>
                            options={countries}
                            value={customer.country}
                            onChange={(obj: Country | null) => onChange('country', obj)}
                            placeholder={messages.country}
                            error={setValidationState(ATTRIBUTES.COUNTRY)}
                            required
                            helperText={messages.countryHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<Language>
                            options={languages}
                            value={customer.language}
                            onChange={(obj: Language | null) => onChange('language', obj)}
                            placeholder={messages.language}
                            helperText={messages.languageHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<CustomerGroup>
                            options={customerGroups}
                            value={customer.group}
                            onChange={(obj: CustomerGroup | null) => onChange('group', obj)}
                            placeholder={messages.group}
                            error={setValidationState(ATTRIBUTES.GROUP)}
                            required
                            helperText={messages.groupHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<VatCode>
                        options={vatCodes}
                        value={customer.vatCode}
                        onChange={(obj: VatCode | null) => onChange('vatCode', obj)}
                        helperText={messages.vatCodeHelpText}
                        placeholder={messages.vatCode}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<string>
                            name="emailAddressInvoice"
                            label={messages.emailInvoice}
                            onChange={onChange}
                            value={customer.emailAddressInvoice}
                            error={setValidationState(ATTRIBUTES.EMAIL)}
                            helperText={messages.emailHelpText}
                            required
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}/>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FormControlLabel
                            control={<Checkbox checked={customer.specificationAttachPDF}
                                onChange={(e: any, value: boolean) => onChange('specificationAttachPDF', value)}
                            />}
                            label={<FormattedMessage {...messages.sendPDF} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<string>
                            name="emailAddressCSV"
                            label={messages.emailCsv}
                            onChange={onChange}
                            value={customer.emailAddressCSV}
                            helperText={messages.emailCsvHelpText}
                            error={setValidationState(ATTRIBUTES.EMAILCSV)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FormControlLabel
                            control={<Checkbox checked={customer.specificationAttachCSV}
                                onChange={(e: any, value: boolean) => onChange('specificationAttachCSV', value)}
                            />}
                            label={<FormattedMessage {...messages.sendCSV} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitGroup>
                            options={unitGroups}
                            value={customer.unitGroup}
                            onChange={(obj: UnitGroup | null) => onChange('unitGroup', obj)}
                            placeholder={messages.unitGroup}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                {customer.id && (
                    <>
                        <Grid item xs={3} />
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <GenericAutocomplete<CustomerStatus>
                                    options={customerStatuses}
                                    value={customer.status}
                                    onChange={(obj: CustomerStatus | null) => onChange('status', obj)}
                                    placeholder={messages.status}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3} />
                    </>
                )}
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FormControlLabel
                            control={<Checkbox checked={customer.visibleInGTS}
                                onChange={(e: any, value: boolean) => onChange('visibleInGTS', value)}
                            />}
                            label={<FormattedMessage {...messages.visibleInGTS} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});

export default AddCustomerStep1;